//引入axios
import axios from 'axios';
import { message } from 'ant-design-vue'

// 创建axios实例
const service = axios.create({
  baseURL: "https://api-express-holiland.1900m.com/",
  timeout: 30000, // 请求超时时间
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  }
})


let defaultParams = {
  macid: "1",
  ver: "1", 
  platform: 'ios',
  token: "b83ee8238b1e359e2c4e85353c25cf6e"
}

//响应拦截器即异常处理
service.interceptors.response.use(
  (res) => {
    if (res && res.data) {
      switch (res.data.code) {
        case 1: //错误
          message.error(res.data.message)
          break;
        default:
          break;
      }
    }
    return res;
  },
  (err) => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = "错误请求";
          break;
        case 401:
          err.message = "未授权，请重新登录";
          break;
        case 403:
          err.message = "拒绝访问";
          break;
        case 505:
          err.message = "http版本不支持该请求";
          break;
        default:
          err.message = `连接错误${err.response.status}`;
      }
    } else {
      err.message = "连接到服务器失败";
    }
    return Promise.resolve(err.response);
  }
);

service.get = function (url, param) {
  // checkToken()
  return new Promise((resolve, reject) => {
    service({
      method: "get",
      url,
      params: { ...param, ...defaultParams },
    }).then(res => {
      if (res && res.status && res.status === 0) {
        resolve(res.data);
      } else {
        message.error(res.message)
        reject(res);
      }
    }).catch(err => {
      reject(err);
    });
  });
},

//post请求
service.post = function (url, param) {
  return new Promise((resolve, reject) => {
    // checkToken()
    service({
      method: "post",
      url,
      data: { ...param, ...defaultParams },
      
    }).then(res => {
      if(res){
        resolve(res.data);
      }else{
        message.error("网络错误")
        reject();
      }
    }).catch(err => {
      message.error(err.message)
      reject(err);
    });
  });
};



/////////////////////门店////////////////////
/**
 * 接单
 * @param {*} params
 * @returns
 */
export function debugTake(params) {
  return service.post("/MySuborder/taking", params);
}

/**
 * 开始配送
 * @param {*} params
 * @returns
 */
export function debugStartDlvr(params) {
  return service.post("/MySuborder/upStatus", params);
}


/**
 * 完成
 * @param {*} params
 * @returns
 */
export function debugFinish(params) {
  return service.post("/MySuborder/finish", params);
}

<template>
<div class="scroll-y-con">
  <div class="top-title" v-if="otherOrderData">
    <div>
      {{ formatOtherOrderSource(otherOrderData.other_order_source) }}订单ID: {{ otherOrderData.other_order_no }} 
      （{{ formatOtherOrderStatus(otherOrderData.other_order_status) }}
      <template v-if="otherOrderData.other_order_status <= 21">，{{isSended?"已发货":"未发货"}}</template>）
      <span class="ml-2">下单时间:{{ otherOrderData.other_order_add_time }}</span>     
    </div>
  </div>

  

  <div class="mt-10">

    <div>
      <a-button @click="handlerStartDebug">debug创建</a-button>
    </div>

    <div v-if="otherOrderData">
      <!-- 底部bar：操作按钮 -->
      <div class="mb-5 flex justify-center" style="align-items:center;"
          v-if="otherOrderData.other_order_status != 13">
        <!-- 1 没有内部订单：建单/忽略 -->
        <template v-if="!order_list.length">
          <!-- 1.1 忽略（可以同步发货 运费补差订单需要） -->
          <a-checkbox class="mr-2" v-model="checkedThirdSend">同步第三方平台发货</a-checkbox>
          <div>
            <a-button type="primary">创建内部订单</a-button>
            <a-checkbox class="ml-2" v-model="isUsePlatFreight">使用平台运费</a-checkbox>
          </div>
        </template>
      </div>
    </div>
      

  </div>


</div>
</template>

<script>
import { 
  formatOtherOrderStatus,
  formatOtherOrderSource,
  formatDetailOrderStatus,
  formatInnerOrderStatus,
  tableWare
} from '@/utils/type'

import {
  debugTake,
  debugStartDlvr,
  debugFinish,
} from "@/api/debug"

import {
  otherOrderGetDetail,
  otherOrderSyncDelivery,
  otherOrderEdit,
} from "@/api/customer"
import { cardList } from "@/utils/type"
import { order_no_list } from "./auto-debug.js"

export default {
  components: {
  },
  data() {
    return {
      cardList,
      tableWare,
      columns_other_order_goods_list: [],

      other_order_id: '',
      onlyRead: false,

      isShowAddFlow: false, //添加退费流水，补差流水
      isShowFlowLog: false, //查看流水记录
      isShowSplitOrder: false, //拆分发货
      isShowRefundApply: false, //售后退款
      refundApplyForm: [],
      isShowExpressDetail: false,

      innerListCurItem: {},
      addressList: [],
      cityList: [],
      isSelectShop: false, //选择门店
      isSelectOutlets: false, 
      isSelectGoods: false, //添加商品

      checkedThirdSend: false,
      isSended: false,

      otherOrderData: null,
      other_order_goods_list: [], // 第三方订单商品列表
      other_order_new_update_data: {},
      order_list: [], //内部订单列表
      addFlowType: '',

      dataUpdateMap: {},

      isUsePlatFreight: true,
      
      hasInnerOrderCompleted: false, // 内部订单有完成的

      open_time: '',

      isLoading: false,


      // auto
      order_no_list,

      // auto_sku_number_list: ["K00030", "K00029","K00028","K00019"],
      auto_sku_number_list: ['K00013', 'K00014', 'K00019', 'K00017', 'K00018', 'K00020', 'K00015', 'K00016', 'K00021', 'K00022', 'K00023', 'K00024', 'K00025', 'K00026', 'K00027', 'K00029', 'K00030', 'K00028'],

      auto_sku_list: {
        "K00013" : {sku_id: 1186},
        "K00014" : {sku_id: 1187},
        "K00015" : {sku_id: 1190},
        "K00016" : {sku_id: 1191},
        "K00017" : {sku_id: 1188},
        "K00018" : {sku_id: 1189},
        "K00019" : {sku_id: 1365},
        "K00020" : {sku_id: 1366},
        "K00021" : {sku_id: 1367},
        "K00022" : {sku_id: 1368},
        "K00023" : {sku_id: 1369},
        "K00024" : {sku_id: 1370},
        "K00025" : {sku_id: 1523},
        "K00026" : {sku_id: 1524},
        "K00027" : {sku_id: 1525},
        "K00028" : {sku_id: 1555},
        "K00029" : {sku_id: 1553},
        "K00030" : {sku_id: 1554}
      }
    }
  },

  created() {
    this.other_order_id = this.$route.query.other_order_id || ''
    this.other_order_no = this.$route.query.other_order_no || ''

    if(this.$route.query.onlyread){ // 已完成时
      this.onlyRead = true
    }
  },
  computed: {
    otherTotalPrice() {
      let price = Number(this.otherOrderData.other_order_amount)
            + Number(this.otherOrderData.other_order_add_amount)
            - Number(this.otherOrderData.other_order_refund_total)
      return Math.round(price*100)/100
    },
  },
  mounted() {

  },

  methods: {
    formatOtherOrderStatus,
    formatOtherOrderSource,
    formatDetailOrderStatus,
    formatInnerOrderStatus,


    // debug创建
    async handlerStartDebug(){
      if(this.order_no_list.length){
        const item = this.order_no_list.shift()
        await this.handlerDebugCreate(item.split(",")[1], item.split(",")[0])
      }else{
        console.log("====== 成功 没有任务了 =======")
      }
    },
    async handlerDebugCreate(other_order_no, index){
      console.log("order_no", other_order_no, index)

      this.other_order_no = other_order_no

      // 请求数据
      let { data, code, timestamp } = await otherOrderGetDetail({
        other_order_no: other_order_no,
      })

      if(code == 0){
        if(data.other_order_status==11){
          const hasOtherSku = data.other_order_goods_list.some(el=>!this.auto_sku_number_list.includes(el.goods_sku_number))
          if(hasOtherSku){
            console.log("有其他产品 跳过", other_order_no)
            this.handlerStartDebug()
          }else{
            this.initOrderData(data, timestamp)
          }
        }else{
          console.log("订单状态不对 跳过", other_order_no, data.other_order_status)
          this.handlerStartDebug()
        }
      }else{
        console.log("有问题 停了", other_order_no, code, data&&data.other_order_status)
      }
    },
    async initOrderData(data, timestamp){
      // 预处理：单价
      data.other_order_goods_list.map(el=>{
        el.single_order_price = Math.round(Number(el.order_price) / el.number * 100) / 100
      })

      this.open_time = timestamp
      this.otherOrderData = data
      this.other_order_id = data.other_order_id
      this.other_order_goods_list = data.other_order_goods_list
      this.other_order_new_update_data = data.other_order_new_update_data
      this.order_list = []
      // this.computedPrice()

      this.isUsePlatFreight = true

      // 创建空订单
      let item = this.createInnerOrderEmpty()
      item.customer_service_remarks = "京东订单-储值卡，批量建单给虚拟配送站“北京销售部”"
      item.goods_list = data.other_order_goods_list.map(gd=>{
        const skuitem = this.auto_sku_list[gd.goods_sku_number]
        return {
          "sku_id": skuitem.sku_id,
          "price": gd.order_price,
          "number": gd.number,
          // "diners_number": 6,
          // "birthday_candle": "1:4",
          // "birthday_card": "生日快乐"
        }
      })

      this.order_list.push(item)
      this.updateFreight()

      this.confirmEdit()
    },


    createInnerOrderEmpty() {
      // console.log("createInnerOrderEmpty", this.otherOrderData)
      let item = {
        "order_no": "",
        "order_type": 2,
        "order_status": "",
        "freight_amount": 0,
        "customer_service_remarks": "",
        "private_remarks": "",
        "pick_up_delivery_order": {
            "suborder_type": 2,
            "outlets_id": "",
            "outlets_name": "",
            "outlets_business_hours":"",
            "outlets_today_business_hours":"",
            "outlets_delivery_hours":"",
            "outlets_today_delivery_hours":"",
            "pick_up_info": {
                "pick_up_goods_time_start": "",
                "pick_up_goods_time_end": "",
                "recipient_name": "",
                "recipient_phone": ""
            },
            "delivery_info": {
                "delivery_time_start": "",
                "delivery_time_end": "",
                "consignee_name": "",
                "consignee_phone": "",
                "consignee_lng_lat": "",
                "consignee_adcode": "",
                "consignee_address": "",
                "consignee_address_detail": "",
            },
            "goods_list": [],
            "cake_accessory_list": []
        },
        "consignee_lng": "",
        "consignee_lat": "",
        "consignee_adcode": "",
        "consignee_address": "",
        "consignee_address_detail": "",
        "goods_time": [],
        "order_goods_amount": 0,
      }


      const name = this.otherOrderData.other_order_consignee_name
      const phone = this.otherOrderData.other_order_consignee_phone
      item.pick_up_delivery_order.pick_up_info.recipient_name   = name
      item.pick_up_delivery_order.pick_up_info.recipient_phone  = phone
      item.pick_up_delivery_order.delivery_info.consignee_name  = name
      item.pick_up_delivery_order.delivery_info.consignee_phone = phone
      item.pick_up_delivery_order.delivery_info.consignee_name  = name
      // 下单人 默认和收货人一致
      item.place_order_name = name
      item.place_order_phone = phone

      item.consignee_address = this.otherOrderData.other_order_consignee_address || ""
      item.consignee_address_detail = ""
      item.consignee_adcode   = "110113001000"
      item.consignee_lat = "40.060043"
      item.consignee_lng = "116.574751"

      item.source_outlets_id = 81 // 供货网店 天竺家乐福店
      item.delivery_region_id   = 438 // 北京销售部的配送范围
      // item.delivery_outlets_type = 2
      // item.delivery_outlets_id   = area.outlets_id
      // item.area_freight_amount = area.freight

      // // 外卖平台：自提/配送时间
      // if(this.otherOrderData.other_order_type == 1){
      //     const pick_date = "2024-07-28"
      //     const pick_time_start = "22:00"
      //     const pick_time_end = "22:00"
      //     item.goods_time = [pick_date, pick_time_start+"-"+pick_time_end]
      // }else if(this.otherOrderData.other_order_type == 2){
          const delivery_date = "2024-07-30"
          const delivery_time_start = "22:00"
          const delivery_time_end = "22:00"
          item.goods_time = [delivery_date, delivery_time_start+"-"+delivery_time_end]
      // }

      return item
    },


    updateFreight(orderItem){
      if(orderItem){
        if(this.isUsePlatFreight){
          orderItem.freight_amount = Number(this.otherOrderData.other_order_freight_amount)
        }else{
          orderItem.freight_amount = orderItem.area_freight_amount || 0
        }
      }else{
        if(this.order_list.length > 1 && this.isUsePlatFreight){
          this.isUsePlatFreight = false
          this.order_list.maps((el,index)=>{
            if(el.order_type == 2){
              if(index > 0){
                el.freight_amount = el.area_freight_amount || 0
              }  
            }
          })
        }  
      }
    },

    //同步第三方平台发货
    async syncDelivery() {
      let params = {
        other_order_id: this.other_order_id
      }
      const { code } = await otherOrderSyncDelivery(params)
      if (code == 0) {
        this.$message.success("已同步第三方发货")
      }
    },

    // 计算订单价格
    computedPrice(order) {
      console.log("computedPrice")
      // debug 缺少按照供货网点 重刷价格的流程

      //价格可手动修改，最后提交的时候计算
      if(order){
        let order_goods_amount = 0
        let goods_list = order.pick_up_delivery_order.goods_list || []
        goods_list.map(gd=>{
          order_goods_amount += Number(gd.order_price)*gd.number
        })
        order.order_goods_amount = Math.round(order_goods_amount*100)/100
      }else{
        this.order_list.map(el=>{
          this.computedPrice(el)
        })
      }
    },
    
    // 提交全部订单数据
    async confirmEdit() {
      let errTip = []

      let params = {
        other_order_id: this.other_order_id,
        open_time: this.open_time,
      }

      let create_order_data = []
      this.order_list.map((element, index)=>{
        if(!element.order_no) {//新建
          let create = {
            tmp_order_id: index+1,
            order_type: element.order_type,
            outlets_id: element.source_outlets_id,
            place_order_name: element.place_order_name,
            place_order_phone: element.place_order_phone,
            freight_amount: element.freight_amount,
            customer_service_remarks: element.customer_service_remarks,
            private_remarks: element.private_remarks,
            pick_up_info: '',
            delivery_info: '',
            goods_sku_data: element.goods_list,
            is_audit: 2,
          }
          if(element.goods_time.length < 2){
            errTip.push("自提时间/配送时间必填")
          }
          if(element.order_type == 1){
            let pick_date = element.goods_time.length==2 ? element.goods_time[0]: ""
            let pick_time = element.goods_time.length==2 ? element.goods_time[1]: ""
            create.pick_up_info = {
              pick_up_goods_time_start: pick_time ? (pick_date+" "+pick_time.split('-')[0]) : "",
              pick_up_goods_time_end:   pick_time ? (pick_date+" "+pick_time.split('-')[1]) : "",
              recipient_name: element.pick_up_delivery_order.pick_up_info.recipient_name,
              recipient_phone: element.pick_up_delivery_order.pick_up_info.recipient_phone,
            }
            create.outlets_id = element.pickup_shop_id
          }
          if(element.order_type == 2){
            let dlvr_date = element.goods_time.length==2 ? element.goods_time[0]: ""
            let dlvr_time = element.goods_time.length==2 ? element.goods_time[1]: ""
            create.delivery_info = {
              delivery_region_id: element.delivery_region_id,
              delivery_time_start: dlvr_time ? (dlvr_date+" "+dlvr_time.split('-')[0]) : "",
              delivery_time_end:   dlvr_time ? (dlvr_date+" "+dlvr_time.split('-')[1]) : "",
              consignee_lng: element.consignee_lng,
              consignee_lat: element.consignee_lat,
              consignee_name: element.pick_up_delivery_order.delivery_info.consignee_name,
              consignee_phone: element.pick_up_delivery_order.delivery_info.consignee_phone,
              consignee_address: element.consignee_address + (element.consignee_address_detail || ""),
              consignee_adcode: element.consignee_adcode,
            }
            create.outlets_id = element.source_outlets_id
          }

          // let cakes = []
          // let others = []
          // element.pick_up_delivery_order.cake_accessory_list.map(el=>el.isHandled = false)
          // element.pick_up_delivery_order.goods_list.map(el => {
          //   if (el.goods_type == 3) {
          //     for (let i = 0; i < el.number; i++) {
          //       let cake_accessory = element.pick_up_delivery_order.cake_accessory_list.find(ck => ck.order_goods_id == el.order_goods_id && !ck.isHandled)
          //       if(!cake_accessory){
          //         cake_accessory = element.pick_up_delivery_order.cake_accessory_list.find(ck => ck.sku_id == el.sku_id && !ck.isHandled)
          //       }
          //       if(cake_accessory) cake_accessory.isHandled = true

          //       let cake = cake_accessory || {}
          //       let item = Object.assign({}, el, {
          //         diners_number:          cake.diners_number,
          //         birthday_candle:        cake.birthday_candle,              
          //         birthday_card:          cake.birthday_card,  
          //         birthday_greeting_card: cake.birthday_greeting_card,
          //       })
          //       cakes.push(item)
          //     }
          //   }else{
          //     others.push({
          //       order_goods_id: el.order_goods_id||0,
          //       number: el.number,
          //       price:  el.order_price,
          //       sku_id: el.sku_id,
          //     })
          //   }
          // })
          // element.pick_up_delivery_order.cake_accessory_list.map(el=>el.isHandled = false)
          // create.goods_sku_data = cakes.map(el=>{
          //   let sku = {
          //     sku_id: el.sku_id,
          //     price: el.order_price,
          //     number: el.number,
          //     diners_number: el.diners_number,
          //     birthday_candle: el.birthday_candle,
          //     birthday_card: el.birthday_card,
          //     birthday_greeting_card: el.birthday_greeting_card,
          //   }
          //   return sku
          // }).concat(others)


          create_order_data.push(create)
        }
      })
      
      if(errTip.length){
        this.$message.info(errTip.shift())
        console.log("创建订单失败", "参数不过")
        return
      }

      params.cancel_order_data = JSON.stringify([])
      params.create_order_data = JSON.stringify(create_order_data)
      params.update_order_data = JSON.stringify([])
      
      const { code, data } = await otherOrderEdit(params)
      if (code == 0) {
        if(data.submit_result == 'error') {
          this.$message.error(data.error_data.error_message)
        }
        if(!data.submit_result||data.submit_result == 'success') {
          console.log("创建成功了", this.other_order_no,)
          this.debugDlvr()
        }
      }else{
        console.log("创建订单失败", code, params)
      }
    },

    async debugDlvr(){
      const other_order_no = this.other_order_no

      // 请求数据
      let { data, code } = await otherOrderGetDetail({
        other_order_no: other_order_no,
      })

      if(code != 0){
        console.log("有问题 停了2", other_order_no)
        return
      }

      if(data.order_list.length != 1){
        console.log("有问题 订单数量不对", other_order_no)
        return
      }

      const suborder_no = data.order_list[0].pick_up_delivery_order.suborder_no

      const res1 = await debugTake({
        suborder_no: suborder_no
      })
      if(res1.code == 0){
        const res2 = await debugStartDlvr({
          suborder_no: suborder_no,
          order_status: 20, // 配送中
        })
        if(res2.code == 0){
          const res3 = await debugFinish({
            suborder_no: suborder_no,
          })
          if(res3.code == 0){
            console.log("-------------------- 成功", this.other_order_no, suborder_no)
            this.handlerStartDebug()
          }else{
            console.log("有问题 送达失败", suborder_no)
          }
        }else{
          console.log("有问题 开始配送失败", suborder_no)
        }
      }else{
        console.log("有问题 接单失败", suborder_no)
      }

    }

  },
}
</script>

<style lang="less">
.has-update {
  background-color: #fff;
  border-color: #f5222d;
  .ant-select-selection {
    border-color: #f5222d;
  }
  .ant-cascader-input {
    border-color: #f5222d;
  }
  input{
    border-color: #f5222d;
  }
}
.dflex {
  display: flex;
  align-items: center;
  &.w-s{
    width: 360px;
  }
  &.w-m{
    width: 470px;
  }
  .label {
    padding: 0 10px;
    min-width: 90px;
  }
  .val {
    flex: 1;
  }
}
.not-form{
  display: flex;
  align-items: center;
  .label {
    padding: 0 10px 0 0;
    min-width: unset;
  }
}
.amount-pannel{
  width: 240px;
  // color: #000;
  .flex .flex{
    width: 200px;
  }
  .grey{
    // color: #aaa;
  }
}
.innerpartydiv{
  padding-top: 0;
}
.inner-order{
  margin-top: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  .order-title{
    background-color: #fafafa;
    border-bottom: 1px solid #d9d9d9;

    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .l {
      font-size: 16px;
      font-weight: 500;
      color: #000;
    }
  }
  .order-content {
    padding: 10px;
  }
}

</style>

<style>
.ant-row-flex .dflex:first-child .label:first-child,
.ant-row .dflex:first-child .label:first-child{
  padding-left: 0;
}
</style>
